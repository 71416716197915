import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Tab,
  Tabs,
  Typography,
  Box,
  Card,
  Grid,
  Button,
  Divider,
  Badge,
  DialogActions,
  DialogContent,
  Dialog,
  TextField,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Header from "../drawer/Header";
import pagerIcon from "../../Assets/Icons/pager-icon.svg";
import { styled } from "@mui/material/styles";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import KeyInformationTable from "./Page/KeyInformationTable";
import KeyInformationTableEdit from "./Page/KeyInformationTableEdit";
import BusinessDescriptionEdit from "./Page/BusinessDescriptionEdit";
import BusinessDescription from "./Page/BusinessDescription";
import KeyMeritsAndConsideration from "./Page/KeyMeritsConsideration";
import KeyMeritsAndConsiderationEdit from "./Page/KeyMeritsConsiderationEdit";
import PriliminaryInvestment from "./Page/PriliminaryInvestment";
import PriliminaryInvestmentEdit from "./Page/PriliminaryInvestmentEdit";
import FinancialOverview from "./Page/FinancialOverview";
import OwnershipManagement from "./Page/OwnershipManagement";
import OwnershipManagementEdit from "./Page/OwnershipManagementEdit";
import SituationOverview from "./Page/SituationOverview";
import SituationOverviewEdit from "./Page/SituationOverviewEdit";
import PastSimilarLooks from "./Page/PastSimilarLooks";
import PublicComps from "./Page/PublicComps";
import OtherRelevantInfoEdit from "./Page/OtherRelevantInfoEdit";
import OtherRelevantInfo from "./Page/OtherRelevantInfo";
import { useSelector, useDispatch } from "react-redux";
import { view2PagerLists, editAccept, editAcceptAll, getAllVersions,  acceptRejectRedraft,
  createReDraftV2 } from "./service";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import * as ExcelJS from "exceljs";
import "./index.css";
import _ from "lodash";
import Loader from "../common/PageLoader";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { resetState } from "../redux/reducer/twoPagerSlice";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ReactMarkdown from "react-markdown";
import MarkdownIt from "markdown-it";
const md = new MarkdownIt();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabs1: {
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
    "& span": {
      "& span": {
        backgroundColor: "#292f62",
        color: "#ffffff",
      },
    },
  },
  indicator: {
    borderRadius: "10px",
    backgroundColor: "white",
  },
}));

let tempstyle = {
  borderLeft: "2.5px solid #7AD167",
  minHeight: "41px",
  paddingRight: "11px",
  backgroundColor: "white",
};

const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#7AD167",
  "&:hover": {
    backgroundColor: "#7AD167",
  },
  padding: "12px",
}));

const FinalPageReviewBtn = styled(Button)(({ theme }) => ({
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
  },
  padding: "12px",
}));
export default function VerticalTabs() {
  const location = useLocation();
  const urlParams = useParams();
  const params = urlParams.userId;
  const paramsVersion = params.split('_').pop();
  const userEmail = JSON.parse(localStorage.getItem("userLoginInfo")).userEmail;
  const twoPagerEdit = useSelector((state) => state.twoPager);
  const dispatch = useDispatch();
  const [editVersion, setEditVersion] = useState({});
  const [releaseVersion, setReleaseVersion] = useState("NA");
  const [KIEditData, setKIEditData] = useState([]);
  const [BDEditData, setBDEditData] = useState([]);
  const [MCEditData, setMCEditData] = useState([]);
  const [PIEditData, setPIEditData] = useState([]);
  const [OMEditData, setOMEditData] = useState([]);
  const [SOEditData, setSOEditData] = useState([]);
  const [ORIEditData, setORIEditData] = useState([]);
  const [companyName, setCompanyName] = useState();
  const navigate = useNavigate();
  const [rowsData, setRowsData] = useState([]);
  const [preliminaryInvestmentTabData, setPreliminaryInvestmentTabData] =
    useState({
      preliminaryInvestment: [],
    });
  const [ownership, setOwnership] = useState({
    ownershipManagement: [],
  });

  const [situationOverviewTabData, setSituationOverviewTabData] = useState({
    ownershipManagement: [],
  });
  const [meritsAndConsideration, setMeritsAndConsideration] = useState({
    merits: [],
    consideration: [],
    meritsHeader: [],
    considerationHeader: [],
  });
  const [acceptedTabs, setAcceptedTabs] = useState(localStorage.getItem("acceptedAll") === "true" ? new Set([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]) : new Set());
  const [value, setValue] = useState(parseInt(localStorage.getItem("section")));

  const handleChange = (event, newValue) => {
    localStorage.setItem("section", newValue);
    setValue(newValue);
  };
  const [businessDesc, setBusinessDesc] = useState({
    companyName: "",
    data: [],
  });
  const [publicComps, setPublicComps] = useState([]);
  const [otherRelData, setOtherRelData] = useState([]);
  const [pastSimilarLoks, setPastSimilarLooks] = useState([]);
  const [customTabEditPage, setCustomTabEditPage] = useState();
  const [finalVersion, setFinalVersion] = useState(params);
  const classes = useStyles();
  const [loading, setLoading] = useState({ status: false, msg: null });
  const [allVersions, setAllVersions] = useState([]);
  const [version, setVersion] = useState({
    name: "",
    id: "",
    edit: ""
  });
  const [adminEdit, setAdminEdit] = useState("");

  const fetchAllVersions = async (parameter) => {
    const response = await getAllVersions(parameter);
    setAllVersions(response?.versions)

    const selectedVersion = response?.versions?.find(v => v?.id === parameter)
    setVersion({ name: selectedVersion.version, id: selectedVersion.id, edit: selectedVersion.edit });
  }
  const handleVersionChange = (event) => {
    const selectedVersion = allVersions.find(v => v.version === event.target.value);
    if (selectedVersion) {
      navigate(`/view-generate-2pager/${selectedVersion.id}`, { state: { doc: location?.state?.doc != undefined ? location?.state?.doc : companyName,
         version: paramsVersion } });
      setVersion({ name: selectedVersion.version, id: selectedVersion.id, edit: selectedVersion.edit });
      window.location.reload();
    }
  };
  const handleUploadResearch = () => {
    navigate(`/create-new-doc/${params}`, {
      state: "UploadResearch",
    });
  }
  let temp = [
    "Key Information",
    "Business Description",
    "Merits & Considerations",
    "Preliminary Investment Thesis",
    "Financial Overview",
    "Ownership / Management",
    "Situation Overview / Next Steps",
    "Past Similar Looks",
    "Public Comps",
    "Other Relevant Information",
  ];

  const handleViewGenrateFile = () => {
    // navigate("/create-new-doc");
    // navigate(-1);
    navigate("/");
  };

  const handleFinalPage = (two_pager_id) => {
    navigate(`/final-2pager/${two_pager_id}`, {
      state: {
        fileName: `${location?.state?.doc}_Two Pager_${formatDate(new Date())}_${paramsVersion}`,
        document: location?.state?.doc != undefined ? location?.state?.doc : companyName,
        version: paramsVersion,
      },
    });
  };

  const handleAccept = async () => {
    try {
      setLoading(prevState => ({ ...prevState, status: true }));

      const response = await editAccept(editVersion);
      if(response?.status === "error"){
        toast.error(response?.data?.message);
        setLoading({ status: false, msg: response?.data?.message });
      }
      if (response?.data?.draft_updated === true) {
        setFinalVersion(response?.data?.two_pager_id);
        setLoading({ status: false, msg: "Data loaded successfully!" });
        navigate(`/view-generate-2pager/${response?.data?.two_pager_id}`,
          { state: { doc: location?.state?.doc != undefined ? location?.state?.doc : companyName,
             version: paramsVersion } });
        fetchAllVersions(response?.data?.two_pager_id);
        window.location.reload();
      }
      else {
        setLoading({ status: false, msg: null });
        setFinalVersion(response?.data?.two_pager_id);
      }
      setCustomTabEditPage(0);
      setAcceptedTabs((prevState) => new Set([...prevState, value]));
      localStorage.setItem("section", value);
      // setValue((prevValue) => prevValue + 1);

      if (value === 9) {
        const allTabs = new Set([...Array(temp.length).keys()]);
        setAcceptedTabs(allTabs);
        // setValue(temp.length - 1);
        localStorage.setItem("section", temp.length);
      }
      dispatch(resetState());
    }
    catch (error) {

      setLoading({ status: false, msg: "Failed!" });

    }
  };

  const parseKeyInformation = (data) => {
    if (data.bullets) {
      const result = data.bullets.map((item) => ({
        name: item?.name,
        value: item?.values[0].result,
      }));
      setRowsData(result);
    }
  };

  const parseMeritsAndConsiderations = (data) => {
    const formattedBullets = data?.map((item) => ({
      name: item?.name,
      description: item?.values.map((value) => value?.result).join(" "),
      page: item?.values?.length > 0 ? item?.values[0]?.page_no : null,
      feedback: item?.feedback,
      number: item?.number,
    }));
    return formattedBullets;
  };

  const parseDataFormatPreliminaryInvestment = (data) => {
    const formattedBullets = data.bullets.map((bullet) => {
      return {
        name: bullet?.name,
        description: bullet?.values?.map((value) => value?.result),
        page: bullet?.values?.length > 0 ? bullet?.values[0]?.page_no : null,
        number: bullet?.number,
        feedback: bullet?.feedback,
      };
    });
    return formattedBullets;
  };

  const parseDataFormat = (data) => {
    const formattedBullets = data.bullets.map((bullet) => {
      return {
        name: bullet?.name,
        description: bullet?.values?.map((value) => value?.result),
      };
    });
    return formattedBullets;
  };

  const parseDataFormatSituationOverview = (data) => {
    const formattedBullets = data.bullets.map((bullet) => {
      return {
        name: bullet?.name,
        description: bullet?.values?.map((value) => value?.result),
      };
    });
    return formattedBullets;
  };

  const transformBusinessDescData = (param) => {
    const data = param.bullets;

    const transformBullets = (bullets) => {
      return bullets.map((bullet) => {
        let bulletObject = {
          name: bullet.name,

          description:
            bullet?.values?.length > 0 ? bullet?.values[0]?.result : null,

          page: bullet?.values?.length > 0 ? bullet?.values[0]?.page_no : null,

          number: bullet?.number,

          feedback: bullet?.feedback,
        };

        if (bullet.sub_bullets && bullet.sub_bullets?.length > 0) {
          bulletObject.subDescription = bullet.sub_bullets.map((subBullet) => ({
            name: subBullet.name,

            description:
              subBullet?.values?.length > 0
                ? subBullet?.values[0]?.result
                : null,

            page:
              subBullet?.values?.length > 0
                ? subBullet?.values[0]?.page_no
                : null,

            number: subBullet?.number,

            feedback: subBullet?.feedback,
          }));
        }

        return bulletObject;
      });
    };

    const transformedBullets = transformBullets(data);

    return transformedBullets;
  };



  useEffect(() => {
    fetchAllVersions(params);
    if (localStorage.getItem("acceptedAll") === "true") {
      setAcceptedTabs(new Set([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]))
    }
    else {
      setAcceptedTabs(new Set())
    }

  }, [])

  useEffect(() => {
    setReleaseVersion(JSON.parse(localStorage.getItem("releaseVersion")));
    if (twoPagerEdit?.backToEditVersion) {
      setFinalVersion(twoPagerEdit?.backToEditVersionId)
    }
  }, [releaseVersion]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sectionNumber = value + 1;
        const response = await view2PagerLists(sectionNumber, params);
        setCompanyName(response?.company_name);
        setAdminEdit(response?.edit)
        // parseData(response?.data?.data);

        if (sectionNumber === 3) {
          const meritsResponse = await view2PagerLists(3, params);
          const merits = parseMeritsAndConsiderations(
            meritsResponse?.data?.bullets[0]?.sub_bullets
          );
          const consideration = parseMeritsAndConsiderations(
            meritsResponse?.data?.bullets[1]?.sub_bullets
          );
          const keyHeaders = meritsResponse?.data?.bullets[0]?.name;
          const considerationHeaders = meritsResponse?.data?.bullets[1]?.name;
          setMeritsAndConsideration({
            merits,
            consideration,
            keyHeaders,
            considerationHeaders,
          });
          setMCEditData(meritsResponse?.data?.bullets);
          return;
        }
        if (sectionNumber === 4) {
          const preliminaryInvestmentResponse = await view2PagerLists(
            4,
            params
          );
          setPIEditData(preliminaryInvestmentResponse?.data?.bullets);
          const preliminaryInvestment = parseDataFormatPreliminaryInvestment(
            preliminaryInvestmentResponse?.data
          );
          setPreliminaryInvestmentTabData({ preliminaryInvestment });
          return;
        }
        if (sectionNumber === 6) {
          const ownershipManagementResponse = await view2PagerLists(6, params);
          setOMEditData(ownershipManagementResponse?.data?.bullets);
          const OwnershipManagementdata = parseDataFormat(
            ownershipManagementResponse?.data
          );
          setOwnership({ OwnershipManagementdata });
          return;
        }
        if (sectionNumber === 7) {
          const situationOverviewResponse = await view2PagerLists(7, params);
          setSOEditData(situationOverviewResponse?.data?.bullets);
          const situationOverviewdata = parseDataFormatSituationOverview(
            situationOverviewResponse?.data
          );
          setSituationOverviewTabData({ situationOverviewdata });
          return;
        }
        if (sectionNumber === 8) {
          const pastSimilarLooksResponse = await view2PagerLists(8, params);
          const array =
            pastSimilarLooksResponse?.data?.bullets[0]?.values[0]?.result;
          setPastSimilarLooks(array);
          return;
        }
        if (sectionNumber === 9) {
          const response = await view2PagerLists(9, params);
          const data = response?.data?.bullets[0]?.values[0]?.result;
          setPublicComps(data);
          return;
        }
        if (sectionNumber === 10) {
          const response = await view2PagerLists(10, params);
          setORIEditData(response?.data?.bullets);
          const resultArray = response.data?.bullets.map((bullet) => ({
            title: bullet.name,
            subtitle: bullet.values[0].result,
          }));
          setOtherRelData(resultArray);
          return;
        }
        if (sectionNumber === 2) {
          const result = transformBusinessDescData(response?.data);
          setBDEditData(response?.data?.bullets);
          setBusinessDesc({
            data: result,
            companyName: response?.data?.company_name,
          });
          return;
        }
        if (sectionNumber === 1) {
          parseKeyInformation(response?.data);
          setKIEditData(response?.data?.bullets);
          return;
        }

      } catch (error) {
        console.log("error:", error);
      }
    };

    fetchData();
  }, [value, version]);

  useEffect(() => {
    setEditVersion({
      id: finalVersion,
      version: "v1",
      email_id: userEmail,
      sections: [
        {
          name: "Key Information",
          number: "I",
          bullets: twoPagerEdit.keyInformation,
        },
        {
          name: "Business Description",
          number: "II",
          bullets: twoPagerEdit.businessDescription,
        },
        {
          name: "Merits & Considerations",
          number: "III",
          bullets: twoPagerEdit.keyMeritsConsideration,
        },
        {
          name: "Preliminary Investment Thesis",
          number: "IV",
          bullets: twoPagerEdit.priliminaryInvestment,
        },
        {
          name: "Ownership / Management",
          number: "VI",
          bullets: twoPagerEdit.ownershipManagement,
        },
        {
          name: "Situation Overview / Next Steps",
          number: "VII",
          bullets: twoPagerEdit.situationOverview,
        },
        {
          name: "Other Relevant Information",
          number: "X",
          bullets: twoPagerEdit.otherRelevantInfo,
        },
      ],
    });
  }, [twoPagerEdit]);

  const parseData = (str) => {
    const lines = str.split("\n").filter((line) => line.trim() !== "");
    const data = lines
      .map((line) => {
        const parts = line.split("->");
        if (parts.length === 2) {
          const key = parts[0].replace(/^\s*\d+:\s*/, "").trim();
          const value = parts[1].trim();
          return { key, value };
        }
        return null;
      })
      .filter((item) => item !== null);
    setRowsData(data);
  };

  const handleAcceptAll = async () => {
    setLoading(prevState => ({ ...prevState, status: true }));
    const response = await editAcceptAll({ id: finalVersion, email_id: userEmail });
    if(response?.status === "error"){
      toast.error(response?.data?.message);
      setLoading({ status: false, msg: response?.data?.message });
    }
    if (response?.data?.version_created === true) {
      const allTabs = new Set([...Array(temp.length).keys()]);
      setAcceptedTabs(allTabs);
      setFinalVersion(response?.data?.two_pager_id);
      setLoading({ status: false, msg: "Data loaded successfully!" });
      navigate(`/view-generate-2pager/${response?.data?.two_pager_id}`, { state: { doc: location?.state?.doc != undefined ? location?.state?.doc : companyName,
        version: paramsVersion } });
      fetchAllVersions(response?.data?.two_pager_id);
      window.location.reload();
      localStorage.setItem("section", 0);
      localStorage.setItem("acceptedAll", "true");
    }
    else {
      const allTabs = new Set([...Array(temp.length).keys()]);
      setAcceptedTabs(allTabs);
      setLoading({ status: false, msg: null });
      setFinalVersion(response?.data?.two_pager_id);
      localStorage.setItem("section", 0);
      localStorage.setItem("acceptedAll", "true");
    }
    setCustomTabEditPage(0);
  };
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#19213D",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#19213D",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#19213D",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#19213D",
      zIndex: 1,
      fontSize: 18,
    },
  }));

  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <RadioButtonCheckedOutlinedIcon className="QontoStepIcon-completedIcon" />
        ) : (
          <RadioButtonUncheckedOutlinedIcon
            className="QontoStepIcon-circle"
            sx={{ fontSize: "large" }}
          />
        )}
      </QontoStepIconRoot>
    );
  }

  const steps = [
    {
      label: "Current version",
      description: ``,
    },
  ];

  function formatDate(inputDate) {
    // Create a new Date object from the input date string
    let date = new Date(inputDate);

    // Extract components of the date
    let month = date.getMonth() + 1; // getMonth() returns zero-based month
    let day = date.getDate();
    let year = date.getFullYear();

    // Ensure month and day are formatted to have leading zeros if necessary
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    // Concatenate the formatted date in MM.DD.YYYY format
    return month + "." + day + "." + year;
  }

  const exportToExcel = async (data) => {
    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet
    const worksheet = workbook.addWorksheet("Sheet1", {
      views: [{ showGridLines: false }], // Remove gridlines
    });
    // Set the zoom level to 45%
    worksheet.views = [{ state: "zoom", zoomScale: 50 }];

    // Define column headers and capitalize the first letter
    const headers = Object.keys(data[0]).map(
      (header) => header.charAt(0).toUpperCase() + header.slice(1)
    );

    const decimalWithPercentageRegex = /^-?\d*\.?\d+%?$/;
    // Add column headers to worksheet, starting from row 2 and column 2
    worksheet.addRow([]);
    worksheet.addRow([undefined, ...headers]);
    data.forEach((row) => {
      // const values = Object.values(row).map(value => value === null || value === undefined ? "" : value)
      const values = Object.values(row).map((value) => {
        if (value && decimalWithPercentageRegex.test(value)) {
          // return parseInt(String(value).replace('%', ''), 10)

          const numericValue = parseFloat(String(value).replace("%", ""));
          // Round to two decimal places
          return Math.round(numericValue * 100) / 100;
        }
        return value === null || value === undefined ? "" : value;
      });
      worksheet.addRow([undefined, ...values]);
    });

    const colOne = worksheet.getColumn("R");
    colOne.eachCell({ includeEmpty: true }, (cell) => {
      if (cell.value) {
        cell.numFmt = `0.00 "%"`;
      }
    });

    // Style header row
    const headerRow = worksheet.getRow(2);
    headerRow.font = {
      bold: true,
      name: "Garamond",
      color: { argb: "FFFFFF" },
    }; // Set font color to white
    headerRow.eachCell((cell) => {
      if (cell.value) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "002060" }, // Dark blue color
        };
        cell.border = {
          top: { style: "thin" }, // Add top border
          bottom: { style: "thin" }, // Add bottom border
          left: { style: "thin" }, // Add left border
          right: { style: "thin" }, // Add right border
        };
      }
    });

    // Set column widths as per content
    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const length = cell.value ? cell.value.toString().length : 0;
        if (length > maxLength) {
          maxLength = length;
        }
      });
      column.width = maxLength > 20 ? 30 : maxLength + 2;
    });

    // Style all rows
    worksheet.eachRow((row, rowNumber) => {
      row.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      }; // Center-align data
      if (rowNumber > 2) {
        // Skip the header row
        row.font = { name: "Garamond" }; // Set font family for data rows
        row.eachCell((cell) => {
          if (
            typeof cell.value === "string" &&
            cell.value.includes("https://")
          ) {
            cell.value = {
              text: cell.value,
              hyperlink: cell.value,
            };
            // Set font color to blue for hyperlinks
            cell.font = { ...cell.font, underline: true, color: { theme: 10 } };
          }
          cell.alignment = {
            vertical: "top",
            horizontal: "center",
            wrapText: true,
          };
          cell.border = {
            top: { style: "thin" }, // Add top border
            bottom: { style: "thin" }, // Add bottom border
            left: { style: "thin" }, // Add left border
            right: { style: "thin" }, // Add right border
          };
        });
      }
    });

    // Generate buffer from workbook
    const buffer = await workbook.xlsx.writeBuffer();

    // Create blob from buffer
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create object URL from blob
    const url = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = url;
    link.download =
      value === 7
        ? `${location?.state?.doc}_Past Similar Looks_${formatDate(
          new Date()
        )}_${paramsVersion}.xlsx`
        : `${location?.state?.doc}_Public Comps_${formatDate(new Date())}_${paramsVersion}.xlsx`;

    // Append link to document body and click it programmatically
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const exportToExcelPublicComps = async (data) => {
    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet
    const worksheet = workbook.addWorksheet("Sheet1", {
      views: [{ showGridLines: false }], // Remove gridlines
    });
    // Set the zoom level to 45%
    worksheet.views = [{ state: "zoom", zoomScale: 50 }];

    // Define column headers and capitalize the first letter
    const headers = Object.keys(data[0]).map(
      (header) => header.charAt(0).toUpperCase() + header.slice(1)
    );

    function isFirstCharacterAlphabet(inputString) {
      const alphabetRegex = /^[A-Za-z]/;
      return alphabetRegex.test(inputString);
    }
    // Add column headers to worksheet, starting from row 2 and column 2
    worksheet.addRow([]);
    worksheet.addRow([undefined, ...headers]);
    data.forEach((row) => {
      // const values = Object.values(row).map(value => value === null || value === undefined ? "" : value)
      const values = Object.values(row).map((value) => {
        if (
          value &&
          !isFirstCharacterAlphabet(value) &&
          typeof value === "string" &&
          value.includes(",")
        ) {
          return parseInt(value.replace(/,/g, ""));
        }
        if (value === "0") {
          return parseInt(value);
        }
        return value === null || value === undefined ? "" : value;
      });
      worksheet.addRow([undefined, ...values]);
    });

    const colOne = worksheet.getColumn("C");
    colOne.eachCell({ includeEmpty: true }, (cell) => {
      if (cell.value && typeof cell.value === "number") {
        cell.numFmt = "#,##0";
      }
    });

    const colTwo = worksheet.getColumn("D");
    colTwo.eachCell({ includeEmpty: true }, (cell) => {
      if (cell.value && typeof cell.value === "number") {
        cell.numFmt = "#,##0";
      }
    });

    const colThree = worksheet.getColumn("F");
    colThree.eachCell({ includeEmpty: true }, (cell) => {
      if (cell.value && typeof cell.value === "number") {
        cell.numFmt = "#,##0";
      }
    });

    const colFour = worksheet.getColumn("H");
    colFour.eachCell({ includeEmpty: true }, (cell) => {
      if (cell.value && typeof cell.value === "number") {
        cell.numFmt = "#,##0";
      }
    });

    // Style header row
    const headerRow = worksheet.getRow(2);
    headerRow.font = {
      bold: true,
      name: "Garamond",
      color: { argb: "FFFFFF" },
    }; // Set font color to white
    headerRow.eachCell((cell) => {
      if (cell.value) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "002060" }, // Dark blue color
        };
        cell.border = {
          top: { style: "thin" }, // Add top border
          bottom: { style: "thin" }, // Add bottom border
          left: { style: "thin" }, // Add left border
          right: { style: "thin" }, // Add right border
        };
      }
    });

    // Set column widths as per content
    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const length = cell.value ? cell.value.toString().length : 0;
        if (length > maxLength) {
          maxLength = length;
        }
      });
      column.width = maxLength > 20 ? 30 : maxLength + 2;
    });

    // Style all rows
    worksheet.eachRow((row, rowNumber) => {
      row.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      }; // Center-align data
      if (rowNumber > 2) {
        // Skip the header row
        row.font = { name: "Garamond" }; // Set font family for data rows
        row.eachCell((cell) => {
          if (
            typeof cell.value === "string" &&
            cell.value.includes("https://")
          ) {
            cell.value = {
              text: cell.value,
              hyperlink: cell.value,
            };
            // Set font color to blue for hyperlinks
            cell.font = { ...cell.font, underline: true, color: { theme: 10 } };
          }
          cell.alignment = {
            vertical: "top",
            horizontal: "center",
            wrapText: true,
          };
          cell.border = {
            top: { style: "thin" }, // Add top border
            bottom: { style: "thin" }, // Add bottom border
            left: { style: "thin" }, // Add left border
            right: { style: "thin" }, // Add right border
          };
        });
      }
    });

    // Generate buffer from workbook
    const buffer = await workbook.xlsx.writeBuffer();

    // Create blob from buffer
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create object URL from blob
    const url = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = url;
    link.download =
      value === 7
        ? `${location.state.doc}_Past Similar Looks_${formatDate(
          new Date()
        )}_${paramsVersion}.xlsx`
        : `${location.state.doc}_Public Comps_${formatDate(new Date())}_${paramsVersion}.xlsx`;

    // Append link to document body and click it programmatically
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  function numberToRoman(num) {
    if (!_.isNumber(num) || num < 1 || num > 3999) {
      throw new Error(
        "Invalid number. Roman numerals can only represent numbers from 1 to 3999."
      );
    }

    const romanNumerals = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1,
    };

    let result = "";

    _.forEach(romanNumerals, (value, key) => {
      while (num >= value) {
        result += key;
        num -= value;
      }
    });

    return result;
  }
  const EditPage = (number) => {
    setCustomTabEditPage(number);
  };
  const noneEditable = [5, 8, 9];

  const StyledSelect = styled(Select)(({ theme }) => ({
    "& select": {
      borderColor: "#DFDFDF", // Set the border color to light grey
      borderWidth: "1px", // Set the border width
    },
  }));

  const [redraftOpenV2, setRedraftOpenV2] = useState(false);
  const [dialogDataV2, setDialogDataV2] = useState([]);
  const [reDraftDataV2, setReDraftDataV2] = useState({});
  const [isRedraftLoadingV2, setRedraftLoadingV2] = useState(false);
  const [dropdownValueV2, setDropdownValueV2] = useState("");
  const [textInputV2, setTextInputV2] = useState("");
  const [titleInputV2, setTitleInputV2] = useState("");
  const [selectedDataSourceV2, setSelectedDataSourceV2] = useState("CIM");

  const handleDataSourceV2 = (event) => {
    setSelectedDataSourceV2(event.target.value);
  };

  const handleRedraftV2 = (args) => {
    setDialogDataV2(args);
    setRedraftOpenV2(true);
  };
  const handleSubmitV2 = async () => {
    console.log(
      preliminaryInvestmentTabData
    );

    try {
      setRedraftLoadingV2(true);
      const formData = {
        two_pager_id: params,
        prompt: textInputV2,
        section: value === 2 ? "3" : "4",
        bullet_name: titleInputV2,
        bullet:
          value === 2
            ? dropdownValueV2 === "1"
              ? "1"
              : "2"
            : String((preliminaryInvestmentTabData?.preliminaryInvestment?.length || 0) + 1),
        sub_bullet:
          value === 2
            ? dropdownValueV2 === "1"
              ? String((meritsAndConsideration?.merits?.length || 0) + 1)
              : String((meritsAndConsideration?.consideration?.length || 0) + 1)
            : "None",
        data_source: selectedDataSourceV2,
      };

      const response = await createReDraftV2(formData);
      if(response?.status === "error"){
        toast.error(response?.data?.message);
      }
      if (response?.status === "SUCCESS") {
        setReDraftDataV2(response);
        toast.success(response?.message);
      } else {
        toast.success(response?.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setRedraftLoadingV2(false);
    }
  };
  const handleReDraftCloseV2 = (event, reason) => {
    if (reason !== "backdropClick") {
      setRedraftOpenV2(false);
      setTextInputV2("");
      setTitleInputV2("");
      setDialogDataV2("");
      setSelectedDataSourceV2("");
      setReDraftDataV2({});
      setDropdownValueV2("");
    }
  };

  const handleTextInputChangeV2 = (event) => {
    setTextInputV2(event.target.value);
  };
  const handleDropdownChangeV2 = (event) => {
    setDropdownValueV2(event.target.value);
  };
  const handleTitleInputChangeV2 = (event) => {
    setTitleInputV2(event.target.value);
  };

  const handleAcceptV2 = async () => {
    try {
      setRedraftLoadingV2(true);
      const parameter = {
        session_id: reDraftDataV2?.session_id,
        accept: true,
      };
      const response = await acceptRejectRedraft(parameter);
      if(response?.status === "error"){
        toast.error(response?.data?.message);
      }
      if (response?.status === "SUCCESS") {
        toast.success(response?.message);
        navigate(`/view-generate-2pager/${response?.two_pager_id}`);
        window.location.reload();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error("API call failed:", error);
    } finally {
      handleReDraftCloseV2();
      setRedraftLoadingV2(false);
    }
  };

  const handleRejectV2 = async () => {
    try {
      const parameter = {
        session_id: reDraftDataV2?.session_id,
        accept: false,
      };
      const response = await acceptRejectRedraft(parameter);
    } catch (error) {
      console.error("API call failed:", error);
    } finally {
      handleReDraftCloseV2();
    }
  };
  const isSubmitEnabledV2 = textInputV2.length >= 20 && titleInputV2.length > 0 && selectedDataSourceV2?.length > 0;

  return (
    <>
      {loading?.status ? <Loader top={"50%"} /> : <></>}
      <Header />
      {/* {!loading?.status && loading?.msg !== null && <Typography
        style={{ textAlign: "center", paddingTop: 20, color: "red" }}
      >{loading?.msg}</Typography>} */}
      <Grid
        md={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "30px 40px 30px 47px",
        }}
      >
        <Grid container>
          <Grid
            md={4}
            display="flex"
            columnGap="10px"
          >
            <img
              src={pagerIcon}
              alt="pager-icon"
              style={{ height: "18px", marginRight: "10px" }}
            />
            <Typography
              style={{ color: "#19213D", fontSize: "16px", fontWeight: "bold" }}
            >
              {companyName}

              <Typography
                style={{
                  color: "#19213D", fontSize: "12px", fontWeight: "light",
                  display: "initial", padding: "0 10px", textTransform: "capitalize"
                }}
              >Version : {version.name}
              </Typography>
            </Typography>
          </Grid>
          <Grid md={8} style={{
            display: "flex",
            justifyContent: "flex-end"
          }}>
            <article>
              <ColorButton
                variant="contained"
                style={{
                  textTransform: "none",
                  height: "30px",
                  marginRight: "10px",
                  // display: adminEdit === "no" ? "none" : "inline-flex"
                }}
                disabled={adminEdit === "no"}
                onClick={handleUploadResearch}
              >
                <Typography className="create-new-doc-btn-text">
                  Upload Supporting Documents
                </Typography>
              </ColorButton>
              <ColorButton
                variant="contained"
                style={{
                  textTransform: "none",
                  height: "30px",
                  marginRight: "10px",
                }}
                onClick={handleAccept}
                disabled={
                  acceptedTabs.has(value) ||
                  version?.edit === "no" ||
                  adminEdit === "no"
                }
              >
                <Typography className="create-new-doc-btn-text">
                  {" "}
                  Accept
                </Typography>
              </ColorButton>
              <ColorButton
                variant="contained"
                style={{
                  textTransform: "none",
                  height: "30px",
                  marginRight: "10px",
                }}
                onClick={handleAcceptAll}
                disabled={
                  acceptedTabs.size === 10
                }
              >
                <Typography className="create-new-doc-btn-text">
                  {" "}
                  Accept All
                </Typography>
              </ColorButton>
              <ColorButton
                variant="contained"
                disabled={
                  acceptedTabs.has(value) ||
                  noneEditable.includes(value + 1) ||
                  version?.edit === "no" ||
                  adminEdit === "no"
                }
                style={{
                  textTransform: "none",
                  height: "30px",
                  marginRight: "10px",
                }}
              >
                <Typography
                  className="create-new-doc-btn-text"
                  onClick={(e) => EditPage(value + 1)}
                >
                  {" "}
                  Edit
                </Typography>
              </ColorButton>
              {(value === 7 || value === 8) &&
                (pastSimilarLoks.length > 0 || publicComps.length > 0) && (
                  <ColorButton
                    variant="contained"
                    onClick={(e) =>
                      value === 7
                        ? exportToExcel(pastSimilarLoks)
                        : value === 8
                          ? exportToExcelPublicComps(publicComps)
                          : ""
                    }
                    style={{
                      textTransform: "none",
                      height: "30px",
                      marginRight: "10px",
                    }}
                  >
                    <Typography className="create-new-doc-btn-text">
                      {" "}
                      Export to Excel
                    </Typography>
                  </ColorButton>
                )}

{(value === 2 || value === 3) && ( 
  <ColorButton
                variant="contained"
                onClick={() => handleRedraftV2(value)}
                style={{
                  textTransform: "none",
                  height: "30px",
                  marginRight: "10px",
                }}
                disabled={version?.edit === "no" ||
                  adminEdit === "no"}
              >
                <Typography className="create-new-doc-btn-text">
                Add Bullet
                </Typography>
              </ColorButton>
)}
              {acceptedTabs.has(9) ? (
                <ColorButton
                  variant="contained"
                  style={{
                    textTransform: "none",
                    height: "30px",
                    marginRight: "10px",
                  }}
                >
                  <Typography
                    style={{ color: "white", fontSize: "13px" }}
                    onClick={() => handleFinalPage(finalVersion)}
                  >
                    {" "}
                    Final Two Pager Review
                  </Typography>
                </ColorButton>
              ) : (
                <ColorButton
                  variant="contained"
                  style={{
                    textTransform: "none",
                    height: "30px",
                    marginRight: "10px",
                    backgroundColor: "#0000001f",
                  }}
                >
                  <Typography
                    style={{ color: "#00000042", fontSize: "13px" }}
                    disabled
                  >
                    {" "}
                    Final Two Pager Review
                  </Typography>
                </ColorButton>
              )}

              <FinalPageReviewBtn
                variant="contained"
                style={{
                  textTransform: "none",
                  height: "30px",
                  marginRight: "10px",
                }}
                onClick={() => handleViewGenrateFile()}
              >

                <Typography style={{ color: "black", fontSize: "13px" }}>
                  <ArrowBackIosIcon style={{ fontSize: "11px" }} />
                  Back
                </Typography>
              </FinalPageReviewBtn>
            </article>
          </Grid>
        </Grid>
      </Grid>
      <Grid md={12} style={{ display: "flex" }}>
        <Grid md={4}>
          <div className="container-with-scrollbar-tab">
            <Tabs
              orientation="vertical"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              // style={{ width: "44%" }}
              sx={{
                borderColor: "divider",
                background: "white",
                marginLeft: 5,
                ".MuiTabs-flexContainer": {
                  gap: "15px",
                },
                ".Mui-selected": {
                  color: "#000000 !important", // Change color to black
                  fontWeight: 600,
                },
              }}
              TabIndicatorProps={{
                className: classes.indicator,
                style: {
                  right: "auto",
                  left: 0,
                  width: "4px",
                  background: "#292F62",
                },
              }}
            >
              {temp.map((label, index) => (
                <Tab
                  label={
                    <Grid display="flex" sx={{ width: "315px", gap: "38px" }}>
                      <Badge
                        badgeContent={numberToRoman(index + 1)}
                        color="success"
                        anchorOrigin={{
                          vertical: "center",
                          horizontal: "left",
                        }}
                      />
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          fontColor: "#666F8D",
                          textTransform: "none",
                        }}
                      >
                        {label}
                      </Typography>
                      {acceptedTabs.has(index) && (
                        <CheckCircleRoundedIcon
                          style={{ color: "#7AD167", marginLeft: "auto" }}
                        />
                      )}
                    </Grid>
                  }
                  {...a11yProps(index)}
                  className={classes.tabs1}
                  style={tempstyle}
                />
              ))}
            </Tabs>
          </div>
          <Card style={{ margin: "0 2% 10% 10%", textAlign: "left" }}>
            <Typography
              style={{
                color: "#666F8D",
                fontSize: "14px",
                fontWeight: "bold",
                textAlign: "left",
                padding: "10px 20px"
              }}
            >
              VERSION HISTORY
            </Typography>
            <FormControl
              sx={{
                margin: "10px 15px 20px",
                width: "60%",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#DFDFDF",
                },
              }}
              size="small"
            >
              <InputLabel id="demo-select-small-label">Select Version</InputLabel>
              <StyledSelect
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={version.name}
                label="Select Version"
                onChange={handleVersionChange}
                style={{ height: 40 }}
              >
                {allVersions?.map((option, index) => (
                  <MenuItem key={index} value={option.version}>
                    {option.version}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>


            {/* <Stack sx={{ width: "100%", marginLeft: "25%" }} spacing={4}>
              <Stepper
                activeStep={2}
                connector={<QontoConnector />}
                orientation="vertical"
              >
                {steps.map((data) => (
                  <Step key={data}>
                    <StepLabel StepIconComponent={QontoStepIcon}>
                      {data.label}
                      {"    "}
                      {data.description}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Stack> */}
          </Card>
        </Grid>
        <Box
          className={value + 1 === 8 ? "" : "container-with-scrollbar"}
          width="1000vh"
          sx={{ borderRadius: "16px", bgcolor: "background.paper" }}
          style={{ marginLeft: "18px", marginRight: "44px", overflowX: "auto" }}
        >
          <Grid>
            <Typography
              style={{
                fontSize: "18px",
                padding: "0 15px",
                fontWeight: "bold",
                color: "#292F62",
              }}
            >
              {temp[value]}
            </Typography>
          </Grid>
          <Divider
            style={{
              background: "#292F62",
              height: "1px",
              color: "#292F62",
              marginTop: "8px",
            }}
          />
          <div style={{ marginBottom: 15, float: "left", width: "100%" }}>
            {value + 1 === 1 ? (
              customTabEditPage === 1 ? (
                <KeyInformationTableEdit data={KIEditData} />
              ) : (
                <KeyInformationTable rows={rowsData} />
              )
            ) : value + 1 === 2 ? (
              customTabEditPage === 2 ? (
                <BusinessDescriptionEdit data={BDEditData} />
              ) : (
                <BusinessDescription
                  data={businessDesc}
                  section={"customTab"}
                />
              )
            ) : value + 1 === 3 ? (
              customTabEditPage === 3 ? (
                <KeyMeritsAndConsiderationEdit data={MCEditData} />
              ) : (
                <KeyMeritsAndConsideration
                  data={meritsAndConsideration}
                  section={"customTab"}
                  redraftStatus={version?.edit}
                />
              )
            ) : value + 1 === 4 ? (
              customTabEditPage === 4 ? (
                <PriliminaryInvestmentEdit data={PIEditData} />
              ) : (
                <PriliminaryInvestment
                  data={preliminaryInvestmentTabData}
                  section={"customTab"}
                  redraftStatus={version?.edit}
                />
              )
            ) : value + 1 === 5 ? (
              <FinancialOverview
                param={params}
                fileName={`${companyName}_Financial Overview_${formatDate(new Date())}_${paramsVersion}.xlsx`}
              />
            ) : value + 1 === 6 ? (
              customTabEditPage === 6 ? (
                <OwnershipManagementEdit data={OMEditData} />
              ) : (
                <OwnershipManagement data={ownership} />
              )
            ) : value + 1 === 7 ? (
              customTabEditPage === 7 ? (
                <SituationOverviewEdit data={SOEditData} />
              ) : (
                <SituationOverview data={situationOverviewTabData} />
              )
            ) : value + 1 === 8 ? (
              <PastSimilarLooks rows={pastSimilarLoks} />
            ) : value + 1 === 9 ? (
              <PublicComps rows={publicComps} />
            ) : value + 1 === 10 ? (
              customTabEditPage === 10 ? (
                <OtherRelevantInfoEdit data={ORIEditData} />
              ) : (
                <OtherRelevantInfo data={otherRelData} section={"customTab"} />
              )
            ) : (
              <></>
            )}
          </div>
        </Box>
      </Grid>
      <Typography style={{ padding: 30, fontSize: 14, textAlign: "center" }}>
        &#169; All Rights Reserved (2024) Veritas Capital : Release Version
        :&nbsp;
        <Typography component='span' style={{ cursor: "pointer", color: "#33348e", fontSize: 14 }} onClick={() => {
          window.open(`${window.location.origin}/release_notes`, '_blank')
        }}>
          {releaseVersion}
        </Typography>
      </Typography>
      <Dialog
        open={redraftOpenV2}
        onClose={handleReDraftCloseV2}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            borderBottom: "1px solid #9e9e9e",
            marginBottom: "15px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              {"Add Bullet"} &nbsp;
              <Typography
                component="span"
                sx={{
                  color: "green",
                  fontSize: "0.75rem",
                  backgroundColor: "#E0F7FA",
                  padding: "0 4px",
                  borderRadius: "4px",
                  display: "inline-block",
                }}
              >
                beta
              </Typography>
              <Typography
                sx={{
                  fontSize: "10px",
                  color: "gray",
                  marginTop: "4px",
                }}
              >
                Generates a new bullet based on user inputs and available data
                from the relevant source or context, independent of existing
                Rainmaker bullets.
              </Typography>
            </Box>
            <HighlightOffIcon
              onClick={handleReDraftCloseV2}
              sx={{
                cursor: "pointer",
                color: "grey.500",
              }}
            />
          </Box>
        </DialogTitle>

        <DialogContent sx={{ padding: "0 16px" }}>
          <Box>
            {value === 2 && (
              <Box sx={{ mb: 1.5 }}>
                <FormControl
                  variant="filled"
                  size="small"
                  sx={{ width: "200px" }}
                >
                  <InputLabel sx={{ fontSize: "0.75rem", padding: 0 }}>
                  Select Merits/Considerations
                  </InputLabel>
                  <Select
                    value={dropdownValueV2}
                    onChange={handleDropdownChangeV2}
                    sx={{
                      "& .MuiSelect-select": {
                        fontSize: "0.75rem",
                        padding: "15px 10px 5px",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: 200,
                          "& .MuiMenuItem-root": {
                            fontSize: "0.75rem",
                            padding: "10px",
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem
                      value="1"
                      sx={{ fontSize: "0.75rem", padding: "4px 8px" }}
                    >
                      {"Merits"}
                    </MenuItem>
                    <MenuItem
                      value="2"
                      sx={{ fontSize: "0.75rem", padding: "4px 8px" }}
                    >
                      {"Considerations"}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}

            <Box sx={{ mb: 1.5 }}>
              <TextField
                label="Bullet Name"
                variant="filled"
                size="small"
                fullWidth
                value={titleInputV2}
                onChange={handleTitleInputChangeV2}
                sx={{
                  width: "500px",
                  "& .MuiInputBase-input": {
                    fontSize: "0.8rem",
                    padding: "15px 12px 5px",
                  },
                  "& .MuiInputLabel-root": { fontSize: "0.75rem" }, // Smaller label
                }}
              />
            </Box>

            <Box sx={{ mb: 1.5 }}>
              <TextField
                label="Please provide your input prompt"
                variant="filled"
                size="small"
                fullWidth
                multiline
                rows={4}
                value={textInputV2}
                onChange={handleTextInputChangeV2}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: "0.8rem",
                  },
                  "& .MuiInputLabel-root": { fontSize: "0.75rem" },
                }}
              />
              {textInputV2.length > 0 && textInputV2.length < 20 && (
                <Typography
                  variant="body2"
                  color="warning.main"
                  sx={{ mt: 0.5, fontSize: "0.7rem" }}
                >
                  Please enter at least 20 characters.
                </Typography>
              )}
            </Box>

            <Box sx={{ mb: 1.5 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend" sx={{ fontSize: "0.75rem" }}>
                  Data Source
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="options"
                  name="radio-buttons-group"
                  sx={{ padding: "0 5px" }}
                  value={selectedDataSourceV2}
                  onChange={handleDataSourceV2}
                >
                  <FormControlLabel
                    value="CIM"
                    control={
                      <Radio
                        sx={{
                          padding: "4px",
                          color: "#7AD167",
                          "&.Mui-checked": { color: "#7AD167" },
                          "& .MuiSvgIcon-root": { fontSize: "1rem" },
                        }}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: "0.75rem" }}>CIM</Typography>
                    }
                  />
                  <FormControlLabel
                    value="CIM_RESEARCH"
                    control={
                      <Radio
                        sx={{
                          padding: "4px",
                          color: "#7AD167",
                          "&.Mui-checked": { color: "#7AD167" },
                          "& .MuiSvgIcon-root": { fontSize: "1rem" },
                        }}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: "0.75rem" }}>
                        CIM and Research
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="CONGRESS"
                    control={
                      <Radio
                        sx={{
                          padding: "4px",
                          color: "#7AD167",
                          "&.Mui-checked": { color: "#7AD167" },
                          "& .MuiSvgIcon-root": { fontSize: "1rem" },
                        }}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: "0.75rem" }}>
                        Congress.gov
                      </Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<SendOutlinedIcon />}
                onClick={handleSubmitV2}
                sx={{
                  textTransform: "capitalize",
                  fontSize: "0.8rem",
                  padding: "4px 10px",
                  backgroundColor: "#7AD167",
                  boxShadow:
                    "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                  "&:hover": {
                    backgroundColor: "#7AD167",
                  },
                }}
                disabled={!isSubmitEnabledV2}
              >
                Submit
              </Button>
            </Box>

            {reDraftDataV2?.value && (
              <Box sx={{ mt: 2 }}>
                <Typography
                  variant="body1"
                  sx={{ mb: 1, fontSize: "0.875rem" }}
                >
                  {"Redrafted response:"}
                </Typography>
                <section
                      style={{
                        color: "#666F8D",
                        fontSize: "14px",
                        textAlign: "justify",
                      }}
                    >
                      

                      <ReactMarkdown
                        children={reDraftDataV2?.value}
                        components={md}
                      />

                   
                    
                    </section>
                
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<CheckIcon />}
                    sx={{
                      textTransform: "capitalize",
                      backgroundColor: "#7AD167",
                      boxShadow:
                        "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                      "&:hover": {
                        backgroundColor: "#7AD167",
                        boxShadow:
                          "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);",
                      },
                      marginRight: 2,
                    }}
                    onClick={handleAcceptV2}
                  >
                    Accept
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    startIcon={<CloseIcon />}
                    sx={{
                      textTransform: "capitalize",
                      backgroundColor: "#F44336",
                      boxShadow:
                        "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                      "&:hover": {
                        backgroundColor: "#F44336",
                        boxShadow:
                          "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);",
                      },
                    }}
                    onClick={handleRejectV2}
                  >
                    Reject
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </DialogContent>

        <DialogActions></DialogActions>
      </Dialog>
      {isRedraftLoadingV2 && (
            <Box
              className="reDraftloader"
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 2000,
              }}
            >
              <AutoAwesomeIcon
                color="inherit"
                fontSize="large"
                sx={{ animation: "zoomBlink 2s infinite ease-in-out" }}
              />
            </Box>
          )}
          <ToastContainer />
    </>
  );
}
